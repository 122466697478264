<nav class="navbar bg-primary fixed-top p-0">
  <a routerLink="/app/customer-info/list"
     class="navbar-brand d-flex align-items-center font-weight-bold pl-0 text-decoration-none text-reset">
    <img class="ml-2" src="assets/img/logo.png" alt="ロゴ" style="width: 30px; height: 30px">
    <span class="ml-2">{{systemName}}</span>
  </a>
</nav>

<div class="d-flex flex-column align-items-center justify-content-center text-center py-5 mt-5">
  <div class="l-main-container">
    <div class="m-card p-error-card">
      <p class="m-mb-2rem">
        <span #errorMessage></span>
      </p>
      <div class="m-txt-center"><a class="m-btn m-btn-secondary" routerLink="/app/customer-info/list">トップへ</a></div>
    </div>
  </div>
</div>
