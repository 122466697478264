import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { HomeFooterComponent } from './home-footer/home-footer.component';
import { HomeHeaderComponent } from './home-header/home-header.component';
import { HomeLeftMenuComponent } from './home-left-menu/home-left-menu.component';


@NgModule({
  declarations: [
    HomeComponent,
    HomeFooterComponent,
    HomeHeaderComponent,
    HomeLeftMenuComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule
  ]
})
export class HomeModule { }
