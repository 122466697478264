import {datadogRum} from '@datadog/browser-rum';
import {environment} from "./environments/environment";
import {DD_VERSION} from "../dd-version";

export const initRum = () =>
  datadogRum.init({
    applicationId: 'f555faf9-f032-4cbd-8f17-111930d74bed',
    clientToken: 'pubbd20777eddd8ddc898f5137bd2993ad5',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'ap1.datadoghq.com',
    service: 'rmt-rakuhaya-admin',
    env: environment.ddEnv,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: DD_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
