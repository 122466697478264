<nav class="navbar bg-primary fixed-top p-0">
  <a routerLink="/app/customer-info/list"
     class="navbar-brand col-9 col-sm-3 col-xl-2 d-flex align-items-center font-weight-bold pl-0 text-decoration-none text-reset">
    <img class="ml-2" src="assets/img/logo.png" alt="ロゴ" style="width: 30px; height: 30px">
    <span class="ml-2">{{systemName}}</span>
  </a>
  <button type="button" data-toggle="collapse" data-target="#global-nav" aria-expanded="false"
          class="btn d-xl-none text-reset" (click)="onClickHamburger()">
    <i class="fas fa-bars h3 mb-0"></i><i class="fas fa-times h3 mb-0"></i>
  </button>
  <ul class="navbar-nav d-none d-xl-flex flex-row">
    <li class="nav-item col-auto text-nowrap">
      <a routerLink="/auth/logout" class="nav-link text-decoration-none text-reset">
        <i class="fas fa-sign-out-alt mr-2"></i>ログアウト
      </a>
    </li>
  </ul>
</nav>
