import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../../services/auth/auth.service";

@Component({
  selector: 'app-logout',
  template: ``,
  styles: []
})
export class LogoutComponent implements OnInit {

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {
    this.authService.logout().subscribe(
      () => this.router.navigate(['auth/login']),
      () => this.router.navigate(['auth/login']),
    );
  }

  ngOnInit(): void {
  }

}
