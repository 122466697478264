import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {provideInjectionTokens} from "./injection-tokens/injection-tokens";
import {provideHttpInterceptors} from "./http-interceptors/http-interceptors";
import {HttpClientModule} from "@angular/common/http";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {OverlayModule} from "@angular/cdk/overlay";
import {ErrorModule} from "./components/error/error.module";
import {AuthModule} from "./components/auth/auth.module";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    ErrorModule,
    AuthModule,
    BrowserAnimationsModule,
  ],
  providers: [
    provideInjectionTokens(),
    provideHttpInterceptors()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
