import {Component} from '@angular/core';
import {ModalDialogComponent} from "../../../util/modal/modal-dialog-component";

@Component({
  selector: 'app-loading-panel',
  templateUrl: './loading-panel.component.html',
  styleUrls: ['./loading-panel.component.scss']
})
export class LoadingPanelComponent extends ModalDialogComponent {
}
