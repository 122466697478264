import {Component, OnDestroy} from '@angular/core';
import {LoadingPanelService} from "./util/loading-panel/loading-panel.service";
import {ModalService} from "./util/modal/modal.service";
import {NavigationStart, Router} from "@angular/router";
import {Subject} from "rxjs";
import {filter, takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  title = 'ClientApp';

  constructor(
    private readonly router: Router,
    private readonly modalService: ModalService,
    private readonly loadingPanelService: LoadingPanelService
  ) {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationStart),
        takeUntil(this.ngOnDestroyed$)
      )
      .subscribe(_ => {
        this.loadingPanelService.close();
        this.modalService.closeAllDialog();
      })
  }

  ngOnDestroyed$ = new Subject();

  ngOnDestroy() {
    this.ngOnDestroyed$.next();
    this.ngOnDestroyed$.complete();
  }
}
