import {ModalService} from "./modal.service";
import {Directive} from "@angular/core";

@Directive()
export class ModalDialogComponent {

  constructor(private readonly modalService: ModalService) {
  }

  close(value?: any): void {
    this.modalService.closeDialog(value);
  }
}
