import {Inject, Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable, of } from 'rxjs';
import {catchError, tap } from 'rxjs/operators';
import { WINDOW } from '../injection-tokens/window/window-token';
import {AuthService} from "../services/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    @Inject(WINDOW) private readonly window: Window
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authService.isAuthorized().pipe(
      tap(x => {
        if (!x) {
          // ここに到達するのは、ログイン済みだけどそのユーザーが無効（削除されていた）場合のみ
          this.authService.setNextPage(this.window.location.href);
          this.redirectToLogout();
        }
      }),
      catchError(error => {
        // 未ログイン、トークンの期限切れ、無効なトークン等のトークンの確認で失敗している場合はこちら
        this.authService.setNextPage(this.window.location.href);
        this.redirectToLogout();
        return of(false);
      })
    );
  }

  private redirectToLogout() {
    this.router.navigate(['auth/logout']);
  }

}
