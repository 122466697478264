import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ErrorRoutingModule} from './error-routing.module';
import {ErrorComponent} from './error.component';
import {HomeModule} from "../home/home.module";
import {UnexpectedErrorComponent} from './unexpected-error/unexpected-error.component';
import {AccountLoginIdConflictComponent} from './account/account-login-id-conflict/account-login-id-conflict.component';
import {DataNotFoundComponent} from './data-not-found/data-not-found.component';
import { DifferentRowVersionComponent } from './differnt-row-version/different-row-version.component';


@NgModule({
  declarations: [
    ErrorComponent,
    UnexpectedErrorComponent,
    AccountLoginIdConflictComponent,
    DataNotFoundComponent,
    DifferentRowVersionComponent
  ],
  imports: [
    CommonModule,
    ErrorRoutingModule,
    HomeModule
  ]
})
export class ErrorModule {
}
