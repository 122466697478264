import {ComponentType} from '@angular/cdk/portal';
import {Observable} from 'rxjs';
import {ModalDialogComponent} from "./modal-dialog-component";

interface ModalDialogEvents<R> {
  opened?: () => void;
  closing?: (val?: R) => Observable<boolean> | Promise<boolean> | boolean; // falseが返ると閉じる処理をキャンセルできる
  closed?: (val?: R) => void;
}

export class ModalDialog<T extends ModalDialogComponent, R = any> {

  constructor(public componentType: ComponentType<T>) {
    this.componentInstance = null;
  }

  /** コンポーネントの参照 */
  componentInstance: T | null;

  /** ダイアログの背後をクリックした際に閉じる機能を有効にするかどうか */
  enableBackdropClickClose = true;

  /** イベント */
  readonly events: ModalDialogEvents<R> = {};
}
