import { Component, Inject, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../services/auth/auth.service";
import {Router} from "@angular/router";
import { WINDOW } from 'src/app/injection-tokens/window/window-token';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginError = false;
  inProcessing = false;
  form = new FormGroup({
    loginId: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [Validators.required]),
  });

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    @Inject(WINDOW) private readonly window: Window
  ) { }

  ngOnInit(): void {
  }

  doLogin(){
    if(this.form.invalid) {
      this.loginError = true;
      return;
    }

    this.inProcessing = true;
    this.authService.login({
      loginId : (this.form.get('loginId')as FormControl) .value,
      password: (this.form.get('password')as FormControl) .value,
    })
      .subscribe(
        () => {
          const nextPage = this.authService.getNextPage();
          if (nextPage) {
            this.window.location.href = nextPage;
          } else {
            this.router.navigate(['/app/customer-info/list']).then();
          }
        },
        () => {
          this.loginError = true;
          this.inProcessing = false;
        }
      );
  }
}
