<app-home-header
  (hamburgerClick)="isCollapsed=$event"
></app-home-header>
<div class="container-fluid">
  <div class="row">
    <app-home-left-menu
      [isCollapsed]="isCollapsed"
    ></app-home-left-menu>
    <main class="main col-xl-11 ml-sm-auto pb-md-5">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
<app-home-footer></app-home-footer>
