import { Injectable } from '@angular/core';
import { AuthNextPageService } from './auth-next-page.service';
import {LoginModel} from "../../models/auth/login-model";
import { Observable } from 'rxjs';
import {AuthApiService} from "../api/auth-api.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private authApiService: AuthApiService,
    private nextPageService: AuthNextPageService,
  ) {
  }

  login(login: LoginModel): Observable<void> {
    return this.authApiService.login(login);
  }

  logout(): Observable<void> {
    return this.authApiService.logout();
  }

  isAuthorized(): Observable<boolean> {
    return this.authApiService.isAuthorized().pipe(map(x => x.result));
  }

  setNextPage(url: string) {
    this.nextPageService.setNextPage(url);
  }

  getNextPage(remove: boolean = true) {
    return this.nextPageService.getNextPage(remove);
  }
}
