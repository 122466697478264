import {Injectable} from '@angular/core';
import {ModalService} from "../modal/modal.service";
import {ModalDialog} from "../modal/modal-dialog";
import {LoadingPanelComponent} from "../../components/shared/loading-panel/loading-panel.component";

@Injectable({
  providedIn: 'root'
})
export class LoadingPanelService {

  private loadingModal!: ModalDialog<LoadingPanelComponent>;

  private alreadyOpen = false;

  constructor(private modalService: ModalService) {
  }

  open() {
    if (this.alreadyOpen) {
      // すでに開いている場合は処理を行わない
      return;
    }

    this.alreadyOpen = true;
    this.loadingModal = new ModalDialog(LoadingPanelComponent);
    this.loadingModal.enableBackdropClickClose = false;
    this.modalService.openDialog(this.loadingModal);
  }

  close() {
    if (!this.alreadyOpen) {
      // 開いていない場合は特に処理を行わない
      return;
    }

    this.modalService.closeDialogBy(this.loadingModal);
    this.alreadyOpen = false;
  }
}
