import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-different-row-version',
  templateUrl: './different-row-version.component.html',
  styleUrls: ['./different-row-version.component.scss']
})
export class DifferentRowVersionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
