import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AppConfig {

  readonly apiConfig = {
    auth: {
      loginUrl: `${environment.pathBaseApi}/auth/login`,
      logoutUrl: `${environment.pathBaseApi}/auth/logout`,
      isAuthorizedUrl: `${environment.pathBaseApi}/auth/is_authorized`
    },
    accountManage: {
      infoUrl: `${environment.pathBaseApi}/account/info`,
      listUrl: `${environment.pathBaseApi}/account/list`,
      loginIdUniqueCheckUrl: `${environment.pathBaseApi}/account/is_login_id_unique`,
      entryUrl: `${environment.pathBaseApi}/account/entry`,
      updateUrl: `${environment.pathBaseApi}/account/update`,
      deleteUrl: `${environment.pathBaseApi}/account/delete`,
    },
    customerInfo: {
      infoUrl: `${environment.pathBaseApi}/customer_info/info`,
      infoSettingUrl: `${environment.pathBaseApi}/customer_info/info_setting`,
      listUrl: `${environment.pathBaseApi}/customer_info/list`,
      updateIdentificationUrl: `${environment.pathBaseApi}/customer_info/update_identification`,
      updateCreditSurveyConfirmationUrl: `${environment.pathBaseApi}/customer_info/update_credit_survey_confirmation`,
      resendSmsUrl: `${environment.pathBaseApi}/customer_info/resend_sms`,
      identificationDocumentImageTableUrl: `${environment.pathBaseApi}/customer_info/identification_document_image_table`,
      identificationDocumentImageBackgroundUrl: `${environment.pathBaseApi}/customer_info/identification_document_image_background`,
      identificationDocumentImageThicknessUrl: `${environment.pathBaseApi}/customer_info/identification_document_image_thickness`,
      insuranceCertificateImageUrl: `${environment.pathBaseApi}/customer_info/insurance_certificate_image`,
      selfieImageUrl: `${environment.pathBaseApi}/customer_info/selfie_image`,
    },
    anDataSyncExecuteInfo: {
      getLatestUrl: `${environment.pathBaseApi}/an_data_sync_execute_info/latest`,
    },
    apiCode: {
      accountManage: 'AMC',
      customerInfo: 'CIC'
    }
  };

  readonly siteConfig = {
    baseTitle: '本人確認サービス',
  };

  readonly customerImageSettings = {
    // width: 640px →1920の1/3
    // height: 400px →1080でヘッダーなど考慮して画面に2枚入るように
    identificationDocumentTable: {
      maxWidth: 640,
      maxHeight: 400,
    },
    identificationDocumentBackground: {
      maxWidth: 640,
      maxHeight: 400,
    },
    identificationDocumentThickness: {
      maxWidth: 640,
      maxHeight: 400,
    },
    insuranceCertificate: {
      maxWidth: 640,
      maxHeight: 400,
    },
    selfie: {
      maxWidth: 640,
      maxHeight: 400,
    }
  }
}
