import {Injectable, Injector, StaticProvider} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Observable, Subject} from 'rxjs';
import {ModalDialog} from './modal-dialog';
import {ModalDialogComponent} from "./modal-dialog-component";

/** OverlayRefとcloseStreamを纏めただけの簡易構造 */
interface ModalContainer<R> {
  overlayRef: OverlayRef;
  closed: Subject<R>;
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private currentContainer: ModalContainer<any> | null;
  private currentDialog: ModalDialog<any> | null;
  private readonly dialogStack: ModalDialog<any>[];
  private readonly containerStack: ModalContainer<any>[];

  // ダイアログを閉じる際に指定された値のキャッシュ
  private readonly closeValueCache: { dialog: ModalDialog<any>; value: any }[];

  constructor(
    private readonly overlay: Overlay,
    private readonly injector: Injector
  ) {
    this.currentContainer = null;
    this.currentDialog = null;
    this.containerStack = [];
    this.dialogStack = [];
    this.closeValueCache = [];
  }


  private injectDialog(dialog: ModalDialog<any>) {
    const providers: StaticProvider[] = [
      {provide: ModalDialog, useValue: dialog}
    ];
    return Injector.create({parent: this.injector, providers});
  }

  /** 新規のオーバーレイを生成する */
  private initDialog<T extends ModalDialogComponent, R>(dialog: ModalDialog<T, R>): ModalContainer<R> {
    const closedStream = new Subject<R>();
    const overlay = this.overlay.create({
      // モーダル用の設定を行う
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically()
    });

    // モーダルの背景をクリックした際のイベントを購読
    overlay.backdropClick().subscribe(event => {
      if (dialog && dialog.enableBackdropClickClose) {
        this.doCloseDialog(dialog);
      }
    });

    // ダイアログが閉じられた際のイベントを購読
    overlay.detachments().subscribe(() => {
      const value = this.removeCloseValueCache(dialog);
      this.detachDialog(dialog);
      if (dialog && dialog.events.closed) {
        dialog.events.closed(value);
      }
      closedStream.next(value);
      closedStream.complete();
    });

    // ダイアログが開かれた際のイベントを購読
    overlay.attachments().subscribe(() => {
      if (dialog && dialog.events.opened) {
        dialog.events.opened();
      }
    });

    return {overlayRef: overlay, closed: closedStream};
  }

  /** 現在のダイアログを返す */
  getCurrentDialog<T extends ModalDialogComponent>(): ModalDialog<T> {
    return this.currentDialog as ModalDialog<T>;
  }

  /**
   * ダイアログを開く
   */
  openDialog<T extends ModalDialogComponent, R>(dialog: ModalDialog<T, R>): Observable<R> {
    // ダイアログが既に表示されている場合はスタックに退避
    if (this.currentContainer !== null && this.currentDialog !== null) {
      this.containerStack.push(this.currentContainer);
      this.dialogStack.push(this.currentDialog);
    }

    const modalContainer = this.initDialog(dialog);
    this.currentContainer = modalContainer;
    this.currentDialog = dialog;
    const injector = this.injectDialog(dialog);
    const componentRef = this.currentContainer.overlayRef.attach(
      new ComponentPortal(dialog.componentType, null, injector));
    dialog.componentInstance = componentRef.instance;

    return modalContainer.closed.asObservable();
  }

  /** ダイアログを閉じる */
  closeDialog(val?: any) {
    if (this.currentDialog !== null) {
      this.doCloseDialog(this.currentDialog, val);
    } else {
      throw new Error('already closed dialog.');
    }
  }

  /** 指定されたダイアログを閉じる */
  closeDialogBy(dialog: ModalDialog<any>, val?: any) {
    this.doCloseDialog(dialog, val);
  }

  /** 全てのダイアログを閉じる*/
  closeAllDialog() {
    if (this.currentDialog) {
      this.doCloseDialog(this.currentDialog)
    }
    while (this.dialogStack.length > 0) {
      this.doCloseDialog(this.dialogStack.pop()!);
    }
  }

  /** ダイアログを閉じる処理の実装 */
  private doCloseDialog(dialog: ModalDialog<any>, val?: any) {
    const closingDialog = dialog;
    const closingOverlay = this.searchOverlayByDialog(closingDialog);
    if (closingDialog !== null && closingOverlay !== null) {
      let closing: Observable<boolean> | Promise<boolean> | boolean = true;
      if (closingDialog.events.closing) {
        closing = closingDialog.events.closing(val);
      }

      if (typeof closing === typeof true) {
        // booleanだったらPromiseに変換
        closing = new Promise<boolean>(resolve => resolve(closing as boolean));
      } else if (closing instanceof Observable) {
        // ObservableだったらPromiseに変換
        closing = closing.toPromise();
      }

      (closing as Promise<boolean>).then(exec => {
        if (exec) {
          // valをキャッシュする
          this.addCloseValueCache(closingDialog, val);
          // closingでキャンセルされなければダイアログを閉じる
          closingOverlay.overlayRef.detach();
        }
      });
    }
  }

  /** 閉じるイベントで渡された値をキャッシュに追加する */
  private addCloseValueCache(dialog: ModalDialog<any>, val: any) {
    this.closeValueCache.push({dialog, value: val});
  }

  /** キャッシュした値を取り出し、キャッシュから削除する */
  private removeCloseValueCache(dialog: ModalDialog<any>) {
    for (let i = 0; i < this.closeValueCache.length; i++) {
      if (this.closeValueCache[i].dialog === dialog) {
        const entry = this.closeValueCache[i];
        this.closeValueCache.splice(i, 1);
        return entry.value;
      }
    }
    return null;
  }

  private detachDialog(dialog: ModalDialog<any>) {
    if (this.currentDialog === dialog) {
      this.currentDialog = this.dialogStack.pop() || null;
      this.currentContainer = this.containerStack.pop() || null;
    } else {
      const index = this.dialogStack.indexOf(dialog);
      if (index >= 0) {
        // current以外のデタッチに対応
        this.dialogStack.splice(index, 1);
        this.containerStack.splice(index, 1);
      } else {
        throw new Error('dialog not found.');
      }
    }
  }

  private searchOverlayByDialog(dialog: ModalDialog<any>) {
    // currentと一致する場合はcurrentを返す
    if (this.currentDialog === dialog) {
      return this.currentContainer;
    }

    // ModalとOverlayのスタック上の位置が一致する前提でModalからOverlayを検索する
    const index = this.dialogStack.indexOf(dialog);
    if (index >= 0) {
      return this.containerStack[index];
    } else {
      throw new Error('dialog not found.');
    }
  }
}
