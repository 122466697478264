import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AppConfig} from "../../../config/app.config";

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent {

  @Input() isCollapsed = true;

  @Output() hamburgerClick = new EventEmitter<boolean>();
  @Output() logoutClick = new EventEmitter<void>();

  systemName: string = '';

  constructor(private readonly appConfig: AppConfig) {
    this.systemName = `${this.appConfig.siteConfig.baseTitle}`;
  }

  onClickHamburger() {
    this.isCollapsed = !this.isCollapsed;
    this.hamburgerClick.emit(this.isCollapsed);
  }

}
