import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {AppConfig} from "../../config/app.config";
import {UnexpectedErrorComponent} from "./unexpected-error/unexpected-error.component";
import {ActivatedRoute} from "@angular/router";
import {AccountLoginIdConflictComponent} from "./account/account-login-id-conflict/account-login-id-conflict.component";
import {AccountManageApiErrorNumber} from "../../constants/account/account-manage-api-error-number";
import {DataNotFoundComponent} from "./data-not-found/data-not-found.component";
import {DifferentRowVersionComponent} from "./differnt-row-version/different-row-version.component";
import {CustomerInfoApiErrorNumber} from "../../constants/customer-info/customer-info-api-error-number";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements AfterViewInit {

  systemName: string = '';
  factory!: ComponentFactory<any>;
  @ViewChild('errorMessage', {read: ViewContainerRef}) viewContainerRef!: ViewContainerRef;

  constructor(
    private readonly resolver: ComponentFactoryResolver,
    private readonly activatedRoute: ActivatedRoute,
    private readonly appConfig: AppConfig
  ) {
    this.systemName = `${this.appConfig.siteConfig.baseTitle}`;
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams
      .subscribe(params => {
        const statusCode = params.statusCode as string;
        const apiCode = params.apiCode as string;
        const errorNumber = params.errorNumber as string;

        this.factory = this.resolver.resolveComponentFactory(
          this.getError(apiCode, errorNumber)
        );
        this.viewContainerRef.createComponent(this.factory);
      })
  }

  private getError(apiCode: string, errorNumber: string): Type<any> {
    switch (apiCode) {
      case this.appConfig.apiConfig.apiCode.accountManage:
        return ErrorComponent.accountManageApiErrorHandle(errorNumber);
      case this.appConfig.apiConfig.apiCode.customerInfo:
        return ErrorComponent.customerInfoApiErrorHandle(errorNumber);
      default:
        return UnexpectedErrorComponent;
    }
  }

  private static accountManageApiErrorHandle(errorNumber: string): Type<any> {
    switch (errorNumber) {
      case AccountManageApiErrorNumber.loginIdConflict:
        return AccountLoginIdConflictComponent;
      case AccountManageApiErrorNumber.notFound:
        return DataNotFoundComponent;
      case AccountManageApiErrorNumber.differentRowVersion:
        return DifferentRowVersionComponent;
      default:
        return UnexpectedErrorComponent;
    }
  }

  private static customerInfoApiErrorHandle(errorNumber: string): Type<any> {
    switch (errorNumber) {
      case CustomerInfoApiErrorNumber.notFound:
        return DataNotFoundComponent;
      case CustomerInfoApiErrorNumber.differentRowVersion:
        return DifferentRowVersionComponent;
      default:
        return UnexpectedErrorComponent;
    }
  }
}
