<nav class="d-xl-block" id="global-nav" [class.collapse]="isCollapsed">
  <nav class="sidebar col-xl-2 bg-primary">
    <div class="sidebar-sticky">
      <strong class="sidebar-heading d-block h6 mb-0 py-2 px-3">顧客情報</strong>
      <ul class="nav flex-column fa-ul ml-0">
        <li class="nav-item">
          <a routerLink="customer-info/list" class="nav-link text-decoration-none text-reset"
             [class.active]="customerInfoModuleRoot.isActive">
            <span class="fa-li"><i class="fas fa-list-ul mr-3"></i></span>顧客一覧・検索
          </a>
          <!--
            customer-info/listのリンクはcustomer-info/hogeでactiveにならない。
            customer-info/**でactiveになるリンクを隠して配置し、テンプレート参照でactiveの情報を取得する。
          -->
          <div routerLinkActive #customerInfoModuleRoot="routerLinkActive" style="display: none"
               routerLink="customer-info"></div>
        </li>
      </ul>
      <strong class="sidebar-heading d-block h6 mb-0 py-2 px-3">利用者管理</strong>
      <ul class="nav flex-column fa-ul ml-0">
        <li class="nav-item">
          <a routerLink="account/list" class="nav-link text-decoration-none text-reset"
             [class.active]="accountManageModuleRoot.isActive">
            <span class="fa-li"><i class="fas fa-list-ul mr-3"></i></span>利用者一覧・検索
          </a>
          <div routerLinkActive #accountManageModuleRoot="routerLinkActive" style="display: none"
               routerLink="account"></div>
        </li>
      </ul>

      <strong class="sidebar-heading d-block h6 mb-0 py-2 px-3" *ngIf="!isCollapsed">その他</strong>
      <ul class="nav flex-column fa-ul ml-0" *ngIf="!isCollapsed">
        <li class="nav-item">
          <a routerLink="/auth/logout" class="nav-link text-decoration-none text-reset">
            <span class="fa-li"><i class="fas fa-sign-out-alt mr-3"></i></span>ログアウト
          </a>
        </li>
      </ul>

    </div>
  </nav>
</nav>
