import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-login-id-conflict',
  templateUrl: './account-login-id-conflict.component.html',
  styleUrls: ['./account-login-id-conflict.component.scss']
})
export class AccountLoginIdConflictComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
