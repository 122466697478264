<div class="d-flex flex-column align-items-center justify-content-center text-center py-5">
  <div class="form-signin w-100 my-auto">
    <h1 class="h1">本人確認サービス</h1>
    <form action="" [formGroup]="form" class="mt-4">
      <div class="">
        <div class="alert alert-danger text-left" role="alert" *ngIf="loginError">
          ログインに失敗しました。
        </div>
        <div class="form-group text-left">
          <label for="login_id">ログインID</label>
          <input class="form-control" type="text" placeholder="ID" id="login_id" formControlName="loginId">
        </div>
        <div class="form-group text-left">
          <label for="password">パスワード</label>
          <input class="form-control" type="password" placeholder="パスワード" id="password" formControlName="password">
        </div>

      </div>
      <button type="submit" class="btn btn-primary btn-block btn-lg p-3" (click)="doLogin()">
        <i class="fas fa-sign-in-alt mr-2"></i>ログイン
      </button>
    </form>
  </div>
</div>
