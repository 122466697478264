import { Injectable } from '@angular/core';
import {AppConfig} from "../../config/app.config";
import {HttpClient} from "@angular/common/http";
import { Observable } from 'rxjs';
import {LoginApiRequestModel} from "../../models/api/auth/login-api-request-model";
import {IsAuthorizedApiResponseModel} from "../../models/api/auth/is-authorized-api-response-model";

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(
    private readonly appConfig: AppConfig,
    private readonly http: HttpClient
  ) {

  }

  login(login: LoginApiRequestModel): Observable<void> {
    return this.http.post<void>(this.appConfig.apiConfig.auth.loginUrl, login);
  }

  logout(): Observable<void> {
    return this.http.get<void>(this.appConfig.apiConfig.auth.logoutUrl);
  }

  isAuthorized(): Observable<IsAuthorizedApiResponseModel> {
    return this.http.post<IsAuthorizedApiResponseModel>(this.appConfig.apiConfig.auth.isAuthorizedUrl, null);
  }
}
