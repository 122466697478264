import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./home.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'account',
        loadChildren: () => import('../account-manage/account.module').then(m => m.AccountModule)
      },
      {
        path: 'customer-info',
        loadChildren: () => import('../customer-info/customer-info.module').then(m => m.CustomerInfoModule)
      },
      {
        path: '**',
        redirectTo: '/404'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {
}
